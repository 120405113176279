import { NewsArticleListItem } from '../app-state/news-articles/news-article.model';

export const isAvailableForPartnerByMembership = (
  newsArticle: NewsArticleListItem,
  communityId: string,
  membershipLevelId: string,
): boolean => {
  const communityEntry = newsArticle.communities.find(item => item.id === communityId);
  if (communityEntry) {
    return communityEntry.membershipLevels.indexOf(membershipLevelId) > -1;
  }
  return false;
};
